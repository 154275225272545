<template>
  <div class="class-list">
    <table-list
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :options="{ selection: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :operates2="operates2(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <goLive ref="goLive"></goLive>
    <el-dialog title="修改回放" :visible.sync="editReviewDialog" width="600px">
      <el-form ref="form" :model="editReviewForm" label-width="80px">
        <el-form-item label="回放id">
          <el-input v-model="editReviewForm.vid"></el-input>
        </el-form-item>
        <el-form-item label="回放平台">
          <el-select
            v-model="editReviewForm.livePlatform"
            placeholder="请选择活动区域"
            style="width:100%"
            disabled
          >
            <el-option label="腾讯云" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回放时长">
          <el-input-number
            v-model="editReviewForm.duration"
            :controls="false"
            :min="0"
            style="width:100%"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editReviewDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitPlayback">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
//表格标题
const tableTitle = '直播管理'
const btns = _this => [
  {
    label: '创建直播',
    type: 'primary',
    method: _this.handleAddLive,
  },
  {
    label: '删除',
    type: 'danger',
    method: _this.handleDel,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '直播编号',
    prop: 'roomLive',
  },
  {
    label: '直播名称',
    prop: 'liveName',
  },
  {
    label: '讲师名称',
    prop: 'teacherName',
    type: 'select',
    children: _this.teacherSelect.map(item => ({
      value: item.teacherName,
      label: item.teacherName,
    })),
  },
  {
    label: '直播状态',
    prop: 'liveStatus',
    type: 'select',
    children: [
      { value: 0, label: '编辑中' },
      { value: 1, label: '待开始' },
      { value: 2, label: '直播中' },
      { value: 3, label: '已结束' },
    ],
  },
  // {
  //   label: '是否自动',
  //   prop: 'publishStatus',
  //   type: 'select',
  //   children: [
  //     { value: 1, label: '是' },
  //     { value: 0, label: '否' },
  //   ],
  // },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'roomLive',
    label: '直播ID',
  },
  {
    prop: 'liveName',
    label: '直播名称',
  },
  {
    prop: 'liveType',
    label: '直播方式',
    formatter: row => {
      if (row.liveType == 0) {
        return 'PC'
      } else if (row.liveType == 1) {
        return 'App'
      } else {
        return '小程序'
      }
    },
  },
  {
    prop: 'teacherName',
    label: '直播讲师',
  },
  {
    prop: 'liveStatus',
    label: '直播状态',
    formatter: row => {
      if (row.liveStatus == 0) {
        return '编辑中'
      } else if (row.liveStatus == 1) {
        return '待开始'
      } else if (row.liveStatus == 2) {
        return '直播中'
      } else {
        return '已结束'
      }
    },
  },
  {
    prop: 'startTimeShow',
    label: '开播时间',
  },
  {
    prop: 'endTimeShow',
    label: '结束时间',
  },
  {
    prop: 'kk',
    label: '观看人次',
  },
  {
    prop: 'publishStatus',
    label: '启用状态',
    render: (h, { row }) => {
      return [
        h('el-switch', {
          //   props: { value: row.publishStatus },
          props: {
            value: row.publishStatus,
            activeValue: 1,
            inactiveValue: 0,
            disabled: row.liveStatus == 3 || row.liveStatus == 2,
          },
          on: {
            change: async v => {
              await _this.handleOpen(v, row)
            },
          },
        }),
      ]
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    isShow: row => row.liveStatus == 0,
    method: _this.handleEdit,
  },
  {
    label: '详情',
    isShow: row => row.liveStatus == 3 || row.liveStatus == 2 || row.liveStatus == 1,
    method: _this.handleDetail,
  },
  {
    label: '确定上播',
    isShow: row => row.liveStatus == 1,
    method: _this.handleUpdateLiveStatus,
  },
  {
    label: '确定下播',
    isShow: row => row.liveStatus == 2,
    method: _this.handleEndLiveStatus,
  },
]
const operates2 = _this => [
  {
    label: '去直播',
    isShow: row => row.liveStatus == 1 || row.liveStatus == 2,
    method: _this.handleGoLive,
  },
  {
    label: '观看回放',
    isShow: row => row.liveStatus == 3,
    method: _this.handleLook,
  },
  {
    label: '修改回放',
    method: _this.handleEditReview,
  },
]
import goLive from './components/goLive'
import TableList from '@/components/TableList'
import {
  liveList,
  updateStatus,
  teacherSelect,
  delLive,
  livePlaybackUpload,
  liveStatusChange,
} from '@/api/live'
import to from 'await-to'
export default {
  components: {
    TableList,
    goLive,
  },
  data() {
    return {
      searchForm,
      columns,
      operates,
      operates2,
      tableTitle,
      btns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
      teacherSelect: [],
      editReviewDialog: false,
      editReviewForm: {
        liveId: '',
        livePlatform: '5',
      },
    }
  },
  created() {
    this.liveListData()
    this.teacherSelectData()
  },
  methods: {
    async liveListData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(liveList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async teacherSelectData() {
      const [res, err] = await to(teacherSelect())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.teacherSelect = res.data
    },
    async handleOpen(v, row) {
      const [, err] = await to(updateStatus({ liveId: row.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '修改成功' })
      row.publishStatus = row.publishStatus == 1 ? 0 : 1
      this.liveListData()
    },
    filterTeacherName() {
      this.selectList.map(v => {
        if (v.teacherId == this.form.liveUserId) {
          this.form.teacherName = v.teacherName
          return this.form.teacherName
        }
      })
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.liveListData()
    },
    handleGoLive(row) {
      this.$refs.goLive.show(row.liveId)
    },
    handleLook(row) {
      this.$router.push({
        path: '/live/livePlayback',
        query: { liveId: row.liveId, orgId: row.orgId, path: 'liveList' },
      })
    },
    async handleUpdateLiveStatus(row) {
      const [, err] = await to(liveStatusChange({ liveRoomId: row.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '上播成功' })
      this.liveListData()
    },
    async handleEndLiveStatus(row) {
      const [, err] = await to(liveStatusChange({ liveRoomId: row.liveId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '下播成功' })
      this.liveListData()
    },
    async handleDel() {
      let arr = []
      this.select.map(v => arr.push(v.liveId))
      const [, err] = await to(delLive({ liveId: arr }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '删除成功' })
      this.liveListData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleAddLive() {
      this.$router.push('live/add')
    },
    // 编辑
    handleEdit(row) {
      this.$router.push(`live/edit/${row.liveId}`)
    },
    async handleDetail(row) {
      this.$router.push({
        path: '/live/liveDetail',
        query: { liveId: row.liveId },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.liveListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.liveListData()
    },
    handleEditReview({ liveId, vid, duration }) {
      this.editReviewForm = {
        liveId,
        vid,
        duration,
        livePlatform: '5',
      }
      this.editReviewDialog = true
    },
    async submitPlayback() {
      const [, err] = await to(livePlaybackUpload(this.editReviewForm))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.editReviewDialog = false
    },
  },
}
</script>
<style lang="scss" scoped>
.class-list {
  .operate-group {
    > span {
      margin-right: 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    width: 50%;
    float: left;
    span {
      display: block;
      margin: 0;
    }
  }
}
::v-deep.el-input-number .el-input__inner {
  text-align: left;
}
</style>
